import axios from "axios";
import { getItem } from '../util/localStorage'
// import { apiUrl } from "../config/config";
import { duration } from "moment";
import moment from "moment";
const apiUrl = process.env.REACT_APP_APP_URL;




export const apiService = {
    login,
    addprediction,
    updateprediction,
    get_prediction,
    get_predictionDetail,
    ChangeStatus,
    changePassword,
    get_users,
    update_status,
    transaction_list,
    contactUs,
    companyProfile,
    changeStatusUser,
    addChatBot,
    addKnowledgeBase,
    getChatBot,
    getKnowledgeBaseList,
    getDashBoardData,
    getChatHistory,
    getAllLeads,
    updateProfileData,
    saveLead
 }


const headers =() =>
    {
        let token = getItem("admin_token")
        console.log("token---,",token)
       return {headers : { 'Authorization': `token ${token}` }} 
    };

//    console.log('headers()....', headers());
async function login(data) {
    return await axios.post(`${apiUrl}/login/`, data);
}
async function addprediction(params,image,startDate) {
  let data = new FormData()
  let timestamp = toTimestamp(startDate)
  // console.log("======timestamp=======",timestamp)
  // console.log("======startDate=======",startDate)
  // return
  data.append("question",params.question)
  data.append("description",params.description)
  data.append("startDateTime",timestamp)
  data.append("duration",params.duration)
  data.append("threshold_time",params.threshold_time)
  data.append("outcome",params.outcome)
  data.append("image",image)
 return await axios.post(`${apiUrl}/admin/add-prediction/`, data,headers());
}
async function updateprediction(params,id,image,startDate) {
  let data = new FormData()
  console.log("======startDate===",startDate)
  let timestamp = toTimestamp(startDate)
//  console.log("======timestamp=======",timestamp)
//  return
 
  data.append("question",params.question)
  data.append("description",params.description)
  data.append("startDateTime",timestamp)
  data.append("duration",params.duration)
  data.append("threshold_time",params.threshold_time)
  data.append("outcome",params.outcome)
  console.log("======image=====",image)
  if(image!=undefined){
    console.log("======image=====",image)
  data.append("image",image)
  }
  return await axios.put(`${apiUrl}/admin/edit-prediction/`+id, data,headers());
}
 const toTimestamp = (strDate) => {
  const dt = moment.utc(strDate).unix();
  return dt;
}
async function get_prediction(page,type,duration){
  return await axios.get(`${apiUrl}/admin/prediction-list?duration=`+duration+`&page=`+page+`&type=`+type, headers())
}
async function get_predictionDetail(id){
  return await axios.get(`${apiUrl}/admin/prediction-detail/`+id, headers())
}
async function changePassword(data){
  return await axios.put(`${apiUrl}/user/company/changepassword/`,data, headers())
}

async function get_users(page,search){
  return await axios.get(`${apiUrl}/user/company/?page==`+page+"&search="+search, headers())
}
async function update_status(id,data){
  return await axios.put(`${apiUrl}/admin/user-status/${id}`,data, headers())
}
async function transaction_list(page,address,walletaddress){
  return await axios.get(`${apiUrl}/admin/transaction-list?page=`+page+"&contract_address="+address+"&wallet_address="+walletaddress, headers())
}

async function ChangeStatus(id,params){
  return await axios.put(`${apiUrl}/change_user_status/${id}/`, params, headers())
}

async function contactUs(page){
  return await axios.get(`${apiUrl}/admin/contactUs-list?page=`+page, headers())
}



async function companyProfile(){
  return await axios.get(`${apiUrl}/profile-company-update/`, headers())
}


async function changeStatusUser(id,params){
  return await axios.post(`${apiUrl}/user/${id}/user_status/`, params, headers())
}

async function addChatBot(data){
  return await axios.post(`${apiUrl}/chatbots/`, data, headers())
}

async function addKnowledgeBase(data,id){
   return await axios.post(`${apiUrl}/chatbots/${id}/`, data, headers())
}

async function getKnowledgeBaseList(id){
  return await axios.get(`${apiUrl}/chatbots/${id}/`, headers())
}

async function getChatBot(){
  return await axios.get(`${apiUrl}/chatbots/`, headers())
}

async function getDashBoardData(){
  return await axios.get(`${apiUrl}/dashboard/`, headers())
}

async function getChatHistory(id){
  return await axios.get(`${apiUrl}/chat-history/${id}/`, headers())
}

async function getAllLeads(id){
  return await axios.get(`${apiUrl}/leads/${id}/`, headers())
}

async function updateProfileData(data){
  return await axios.put(`${apiUrl}/profile-company-update/`, data, headers())
}

async function saveLead(data){
  return await axios.post(`${apiUrl}/leads/`, data, headers())
}

 

 
 
  






















