import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { InlineLoader } from "../../components/loader";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../../components/loader";
import 'react-toastify/dist/ReactToastify.css';
import { apiService } from "../../service/api.service";
import { BadRequest, MyContext, Unauthorized, formatBalance, fundWalletAddress } from "../../config/config";
import { useContext } from "react";
export const Home = () => {
  const [loader, setLoader] = useState(false);
  const [totalChatbot, setTotalChatbot] = useState(0);
  const [activeChatbot, setActiveChatbot] = useState(0);
  const [tokenUsed, setTokenUsed] = useState(0);
  const [recentChat, setRecentChat] = useState([]);
  const [show, setShow] = useState(false);
  useEffect(() => {
    getDashBoardData()
  }, [])

  const handleClose = () => {
    setShow(false)
  }

  const getDashBoardData = () => {
    setLoader(true)
    apiService.getDashBoardData()
      .then((res) => {
        setLoader(false)
        setTotalChatbot(res.data?.chatbots)
        setActiveChatbot(res.data?.active_bots)
        setTokenUsed(res.data?.chats)
        setRecentChat(res.data?.recent_chats)

        console.log("dashboard", res.data)
      })
      .catch((err) => {
        setLoader(false)
        console.log(err)
        if (err?.response?.status == 401) {
          Unauthorized()
        } else {
          BadRequest(err?.response?.data?.message)
        }
      })
  }

  return (
    <>
      <div className="dashboard-main-area">
        {loader && <ThreeDotSpinner />}
        <div className="dashboard-area main-home-top-area">

          <Row className="justify-content-center">

            <Col md={12} lg={12} className="p-0">
              <Row className="justify-content-center m-2">
                {loader == true ? <ThreeDotSpinner /> : ""}

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards card-top-one">
                    <div className="dashboard-box-vector">
                      <img src={require("../../assets/images/login-bg.svg").default} />
                    </div>

                    <div className="dashboard-outer">
                      <div className="card-content">
                        <h3>Total Chatbots</h3>
                        <h2>{totalChatbot}</h2>
                      </div>
                      <div className="dashboard-box-icon">
                        <img src={require("../../assets/images/total-chatbot.svg").default} alt="total-chatbot" />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="dashboard-box-vector">
                      <img src={require("../../assets/images/login-bg.svg").default} />
                    </div>

                    <div className="dashboard-outer">
                      <div className="card-content">
                        <h3>Active Chatbots</h3>
                        <h2>{activeChatbot}</h2>
                      </div>
                      <div className="dashboard-box-icon">
                        <img src={require("../../assets/images/active-chatbot.svg").default} alt="total-chatbot" />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards card-top-third">
                    <div className="dashboard-box-vector">
                      <img src={require("../../assets/images/login-bg.svg").default} />
                    </div>

                    <div className="dashboard-outer">
                      <div className="card-content">
                        <h3>Token Used</h3>
                        <h2>{tokenUsed}</h2>
                      </div>
                      <div className="dashboard-box-icon">
                        <img src={require("../../assets/images/token.svg").default} alt="total-chatbot" />
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>

            </Col>

            <Col md={12} lg={12}>
              <div className="recent-conversation">
                <h3>Recent Conversation</h3>
                <div className="knowledge-base">

                  <Table >
                    <thead>
                      <tr>
                        <th>Chatbot Name</th>
                        <th>Time of engagement</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Engagement Duration</th>
                        <th>Preview</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentChat && recentChat.length > 0 ?
                        recentChat.map((res) => {
                          return (<>
                            <tr>
                              <td>{res.name}</td>
                              <td>{ }</td>
                              <td>{ }</td>
                              <td>{ }</td>
                              <td>{ }</td>
                              <td><a href="#">View Conversation</a></td>
                            </tr>
                          </>)
                        })
                        :
                        <tr>
                          <td colSpan={6}>
                            <div className="no-data-area">
                              <img src={require("../../assets/images/no-data.gif")} alt="no-data" />
                              <h6>No Data Found</h6>
                            </div>
                          </td>
                        </tr>

                      }

                    </tbody>
                  </Table>
                </div>
              </div>

            </Col>

          </Row>
        </div>
      </div>

      {/* modal */}
      <Modal show={show} onHide={handleClose} centered size="lg" className="view-conversation-popup">
        {/* <Modal.Header closeButton>
      
        </Modal.Header> */}
        <Modal.Body>
          <div className="chat-bot-content-left">
            <div className="chatbot-name-top ">
              <h6>Chatbot</h6>
              <i class="fas fa-times"></i>
            </div>
            <div className="chat-bot-content-top">
              <div className="chat-outer-area">
                <div className="chat-left">
                  <h5>Hello</h5>
                  <p>Feb 9, 2024 3:02 PM</p>
                </div>
                <div className="chat-right">
                  <h5>Hi</h5>
                  <p>Feb 9, 2024 3:02 PM</p>
                </div>
              </div>

            </div>


          </div>
        </Modal.Body>

      </Modal>
    </>
  );
};
