// import { useEffect, useState } from "react";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import swal from "sweetalert";
import { apiService } from "../../service/api.service";
import { Unauthorized } from "../../config/config";
import useWebSocket, { ReadyState } from 'react-use-websocket';

const Chat = () => {
  const [chat, setChat] = useState([]);
  const [query, setQuery] = useState("");
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const chatbot = JSON.parse(localStorage.getItem('chatbot'));
  const [historyID,setHistoryID] = useState('');
  const chatbotData = JSON.parse(localStorage.getItem('chatbot'));

  const chatUrl = process.env.REACT_APP_BASE_URL + `/ws/chat/${historyID}/${chatbotData.id}`;

  const { sendMessage, lastMessage, readyState } = useWebSocket(`${chatUrl}`);

  const handleQuerySubmit = () => {
    if (query == '') {
      swal('Error', "Please provide some content.", 'error')
    } else {
      setChat((prevObj) => [...prevObj, { type: "question", message: query }])
    }

  }
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  const saveLeadData = (id) => {
   var formdata = new FormData();
       formdata.append('chatbot',id)
       formdata.append('user_name', userName)
       formdata.append('user_email', email)
       formdata.append('phone', phone)
       formdata.append('location', JSON.stringify(position))  
  
     console.log(formdata);           

     apiService.saveLead(formdata)
               .then((res) => {
                console.log("reseeee", res)
                setHistoryID(res.history_id)
                setFormSubmitted(true);
               })
               .catch((err) => {
                 if(err.response?.status == 401){
                   Unauthorized()
                 }
               })  
  }

  console.log("position",position)

  return (
    <>
      <div className="inner-chatbot">
        <Container>
          <Row className="justify-content-center">
            {formSubmitted && <Col md={10}>
              <div className="mid-chat-box">
                <div class="chatbox-header">
                  <div class="inner-chatbot-main">
                    <h3>AI Health Assistant ✍️</h3>

                  </div>
                  <span>
                  </span>
                </div>
                <div class="chat-bot-content-top">
                  <div class="chat-outer-area">
                    <div class="chat-left">
                      <h5>hi</h5>
                    </div>
                    <div class="chat-right">
                      <h5>Hello! How can I assist you today?</h5>
                    </div>
                  </div>
                </div>
             
                <div className="chatbox-footer">
                  <form>
                    <textarea id="input_focused"  class="noSelect" placeholder="Ask something about your health!" onChange={(e) => {setQuery(e.target.value)}}
                    onKeyDown={(e) => {
                      
                    }}
                    ></textarea>
                    <div class="chat-module">
                      <div class="send-record-btn">
                        <button type="button" onClick={() => {handleQuerySubmit()}} disabled="" class="send-btn-disabled submit submit-light">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                  <p class="sub-para">AI Health Assistant may produce inaccurate information about people, places, or facts.</p>
                </div>
              </div>
            </Col>}
            {!formSubmitted &&
            <Col md={10} lg={7} xl={6}>
              <div className="change-password-area">
                <h2 className="chamge-text">Detail</h2>
                <Form >
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value)
                      }}
                      placeholder="Enter Name" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                      placeholder="Enter Email" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value)
                      }}
                      placeholder="Enter Phone Number" />
                  </Form.Group>
                  <Button variant="primary" onClick={() => {saveLeadData(chatbot.id)}} className="login-btn-submit">
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
            }
          </Row>
        </Container>
      </div>
    </>
  )
};

export default Chat;
