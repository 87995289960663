import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { apiService } from "../service/api.service";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { BadRequest, Unauthorized } from "../config/config";
import { ThreeDotSpinner } from "../components/loader";



function AddChatBot(props) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const [chatbotfield, setChatbotField] = useState({
    chatbot_name: "",
    open_ai_key: "",
    knowledge_base: null,
    chat_persona: "",
  });

  const [chatbotfieldError, setChatbotFieldError] = useState({
    chatbot_name: "",
    open_ai_key: "",
    knowledge_base: "",
    chat_persona: "",
  });
  let regex = /^[a-zA-Z]+$/;
  let allowed_files = ["application/pdf", "text/plain"];

  const showError = (field, error) => {
    setChatbotFieldError((prevObj) => {
      return {
        ...prevObj,
        [field]: error,
      };
    });
  };

  const setFieldValue = (field, value) => {
    if (value) {
      setChatbotField((prevObj) => {
        return {
          ...prevObj,
          [field]: value,
        };
      });

      setChatbotFieldError((prevObj) => {
        return {
          ...prevObj,
          [field]: "",
        };
      });
    }

  };

  const validateForm = (values) => {
    if (values.chatbot_name == '' || values.chatbot_name == null) {
      showError("chatbot_name", "Chatbot name is required.");
      return false;
    } else if (values.open_ai_key == '' || values.open_ai_key == null) {
      showError('open_ai_key', "Open Ai key is required.");
      return false;
    } else if (values.knowledge_base == '' || values.knowledge_base == null) {
      showError('knowledge_base', "Knowledge base is required.");
      return false;
    } else if (values.chat_persona == '' || values.chat_persona == null) {
      showError('chat_persona', "Chat persona is required.");
      return false;
    } else {
      setChatbotFieldError((prevObj) => {
        return {
          ...prevObj,
          [values]: "",
        };
      });

      return true
    }
  }

  const handleSubmit = () => {

    if (validateForm(chatbotfield)) {
      let formdata = new FormData();
      formdata.append('name', chatbotfield.chatbot_name);
      formdata.append('api_key', chatbotfield.open_ai_key);
      formdata.append('files', chatbotfield.knowledge_base);
      formdata.append('prompt', chatbotfield.chat_persona);
      setLoader(true);
      apiService.addChatBot(formdata)
        .then((res) => {
          setLoader(false);
          if (res.status == 200) {
            navigate('/chatbotdetail')
          }
        })
        .catch((err) => {
          setLoader(false);
          if (err?.response?.status == 401) {
            Unauthorized()
          } else {
            BadRequest(err?.response?.data?.message)
          }
        })
    }
  };


  return (
    <>
      <div className="dashboard-main-area">
        {loader && <ThreeDotSpinner />}
        <div className="dashboard-area main-home-top-area">
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="configuration-area chatbot-creation">
                <h6>Chatbot Creation </h6>
                <Form
                  encType="multipart/form-data"
                >
               
                    <Form.Group className="mb-4 knowledgebase-file" >
                      <Form.Label>Uplaod KnowledgeBase File*</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="Uplaod KnowledgeBase File"
                        onChange={(e) => {
                          if (!e.target.files || !e.target.files[0]) {
                            showError(
                              "knowledge_base",
                              "Knowledge base is required."
                            );
                            chatbotfield.open_ai_key = null;
                          } else if (!allowed_files.includes(e.target.files[0].type)) {
                            showError(
                              "knowledge_base",
                              "Select a valid extension file."
                            );
                            chatbotfield.open_ai_key = null;
                          } else {
                            setFieldValue("knowledge_base", e.target.files[0]);
                          }
                        }}
                      />
                    <span className="error">{chatbotfieldError.knowledge_base}</span>
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                    </Form.Group>
               
               
                  <Form.Group className="mb-4" >
                    <Form.Label>Chatbot Name*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Chatbot Name"
                      onChange={(e) => {
                        if (e.target.value.trim() === "") {
                          showError(
                            "chatbot_name",
                            "Chatbot name is required."
                          );
                          chatbotfield.chatbot_name = '';
                        } else if (!regex.test(e.target.value)) {
                          showError(
                            "chatbot_name",
                            "Please Enter a valid chatbot name."
                          );
                          chatbotfield.chatbot_name = '';
                        } else {
                          setFieldValue("chatbot_name", e.target.value);
                        }
                      }}
                    />
                    <span className="error">{chatbotfieldError.chatbot_name}</span>
                  </Form.Group>

                  <Form.Group className="mb-4" >
                    <Form.Label>OpenAi Key*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter OpenAI Key"
                      onChange={(e) => {
                        if (e.target.value.trim() === "") {
                          showError("open_ai_key", "Open Ai key is required.");
                          chatbotfield.open_ai_key = '';
                        } else {
                          setFieldValue("open_ai_key", e.target.value);
                        }
                      }}
                    />
                    <span className="error">{chatbotfieldError.open_ai_key}</span>
                  </Form.Group>

                  <Form.Group className="mb-4" >
                    <Form.Label>Chatbot Persona*</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Chatbot Persona"
                      rows={4}
                      onChange={(e) => {
                        if (e.target.value.trim() === "") {
                          showError(
                            "chat_persona",
                            "Chatbot persona is required."
                          );
                          chatbotfield.chat_persona = '';
                        } else {
                          setFieldValue("chat_persona", e.target.value);
                        }
                      }}
                    />
                    <span className="error">{chatbotfieldError.chat_persona}</span>
                  </Form.Group>
                  <Button variant="primary" type="button" onClick={() => {
                    handleSubmit();
                  }}>
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AddChatBot;
