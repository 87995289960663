import { createContext } from "react";
import swal from "sweetalert";

 export const apiUrl = "https://chat-api.aioffice.pro/api"
 export const baseUrl = "https://chat-api.aioffice.pro"


export const MyContext = createContext();
export const feedWalletAddress = "0x4887c63c2D322D4639b8128cb5C3C58849A732C9"
export const fundWalletAddress = "0xc42F48a8E1C2721FA25fdfDBb6A7d5e256A63694"

export const Unauthorized=()=>{swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
    localStorage.clear()
    window.location.href = "/";
    console.log('OK button clicked after error alert');
})}

export const BadRequest=(message)=>{swal({ icon: 'error', text: message, button: "OK" }).then(() => {
    
})}


export const  dateFormate = 'DD/MM/YYYY hh:mm A'
export const formatBalance = (rawBalance) => {
    // console.log((rawBalance/ 1000000000000000000))
    const balance = (rawBalance / 1000000000000000000).toFixed(2)
    return balance
  }
  export const transactionurl =  "https://mumbai.polygonscan.com/tx/"