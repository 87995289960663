import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Col, Row, Table, Form, OverlayTrigger, Tooltip, Modal, Tabs, Tab, Accordion } from "react-bootstrap";
import { InlineLoader } from "../../components/loader";
import { ThreeDotSpinner } from "../../components/loader";
import { Unauthorized } from "../../config/config";
import { useContext } from "react";
import { context } from "../../GlobalContext/GlobalContext";
import swal from "sweetalert";
import { apiService } from "../../service/api.service";
import useWebSocket, { ReadyState } from 'react-use-websocket';



export const Chatbots = () => {
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [codeSuccess, setCodeSuccess] = useState("");
  const [apiSuccess, setApiSuccess] = useState("");
  const [urlSuccess, setUrlSuccess] = useState("");
  const [fileError, setFileError] = useState("");
  const [file, setFile] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [readonly, setReadonly] = useState(true);
  const [apiKey, setApiKey] = useState("");
  const [apiKeyError, setApikeyError] = useState("");
  const [knowledgeBase, setKnowledgeBase] = useState([]);
  const [chatTriggered, setChatTriggered] = useState(false);
  const [query, setQuery] = useState("");
  const [randomString, setRandomString] = useState("1234");
  const [chat, setChat] = useState([]);
  const [showConversationModal, setShowConversationModal] = useState(false)
  const generatedAnswer = useRef("");

  const [showChatbot, setShowChatbot] = useState(false);
  const globalState = useContext(context);
  const chatbotData = JSON.parse(localStorage.getItem('chatbot'));
  const [socketResponse, setSocketResponse] = useState('');
  // console.log("chatbotData", )
  const chatUrl = process.env.REACT_APP_BASE_URL + `/ws/chat/${randomString}/${chatbotData.id}`;
  console.log(chatUrl);

  const { sendMessage, lastMessage, readyState } = useWebSocket(`${chatUrl}`);
  const handleClose = () => {
    setShowConversationModal(false)
  }

  useEffect(() => {
    getKnowledgeBase(chatbotData.id);
    getChatHistory(chatbotData.id);
    getAllLeads(chatbotData.id);
  }, [])


  useEffect(() => {
    makeid(6)
  }, [])



  useEffect(() => {
    if (lastMessage !== null) {
      console.log("lastmassage--", readyState, JSON.parse(lastMessage.data));
      let messageResponse = JSON.parse(lastMessage.data);
      if (!messageResponse.stream) {
        getChatHistory(chatbotData.id)
        setChatTriggered(false);
        let answer = generatedAnswer.current;
        setChat((prevObj) => [...prevObj, { type: "answer", text: answer }]);
        generatedAnswer.current = "";
        setSocketResponse('');

      } else {
        setSocketResponse((prevResponse) => prevResponse + messageResponse.message);
        generatedAnswer.current = socketResponse;
      }

    }
  }, [lastMessage]);

  const handleCloseChatbot = () => setShowChatbot(false);
  const handleShowChatbot = () => setShowChatbot(true);
  let allowedExtension = ["application/pdf", "text/plain"];

  const closeModal = () => {
    setShowModal(false);
  }

  const getChatHistory = (chat_id) => {
    apiService.getChatHistory(chat_id)
      .then((res) => {
        console.log("resres", res.data)
        // localStorage.setItem("chatbot", JSON.stringify(data));
      })
      .catch((err) => {
        if (err.response?.status == 401) {
          Unauthorized();
        }
      })
  }


  const getAllLeads = (chatId) => {
    apiService.getAllLeads(chatId)
              .then((res) => {
                 console.log("lead result", res)
              })
              .catch((err) => {
                if(err.response?.status == 401){
                  Unauthorized();
                }
              })
  }

  const handleSendMessage = useCallback((data) => sendMessage(data), []);

  const copyContent = (id) => {
    var copyText = document.getElementById(id);
     console.log("code_snippet",id, copyText);
    if(copyText.value){
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      if (navigator.clipboard.writeText(copyText.value)) {
        if (id === 'code_snipet') {
          setCodeSuccess('copied');
        } else if (id === 'get_api') {
          setApiSuccess('copied');
        } else {
          setUrlSuccess('copied');
        }
      }
      setTimeout(() => {
        setCodeSuccess('');
        setApiSuccess('');
        setUrlSuccess('');
      }, 3000);

    } else {
      swal("Error", "Please enter some content to copy.", "error");
    }


  }



  const addKnowledgeBase = (id) => {
    let formdata = new FormData();
    formdata.append('files', file);

    apiService.addKnowledgeBase(formdata, id)
      .then((res) => {
        console.log(res);
      })
      .catch(err => console.log('Err', err))
  }

  const getKnowledgeBase = (id) => {
    apiService.getKnowledgeBaseList(id)
      .then((res) => {
        console.log("knowledgebase111", res)
        if (res.data?.data?.length > 0) {
          setApiKey()
          setKnowledgeBase(res.data?.data);
        }
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          Unauthorized()
        }
        console.log('Err', err)
      })
  }

  const editApiKey = () => {
    let element = document.getElementById('ai_key');
    if (element) {
      let text = element.value;
      console.log(text);
    }
  }

  const handleSubmitQuery = () => {
    setChatTriggered(true);
    if (query.trim() == "") {
      swal("Warning", "Enter some query to ask!", "warning");
    } else {
      var data = { question: query }
      setChat((prevObj) => [...prevObj, { type: "question", text: query }]);
      setQuery("");
      handleSendMessage(JSON.stringify(data));
    }

  }

  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    setRandomString(result);
  }

  return (
    <>
      {!globalState.showAddChatForm &&
        <div className="dashboard-main-area">
          <div className="dashboard-area main-home-top-area">
            <Row>
              <Col md={12}>
                <div className="back-top-btn">
                  <Button type="button" variant="unset"><i class="fas fa-long-arrow-alt-left"></i> Back</Button>
                </div>
                <div className="chatbotcontent">
                  <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="home" title="ChatView">
                      <Row>
                        <Col md={7}>
                          <div className="chat-bot-content-left">
                            <h6>{chatbotData.name}</h6>
                            <div className="chat-bot-content-top">
                              <div className="chat-outer-area">
                                {chat && chat.length > 0 ?

                                  chat.map((res) => {
                                    if (res.type == 'answer') {
                                      return (<>
                                        <div className="chat-right">
                                          <h5>{res.text}</h5>
                                          <p>Feb 9, 2024 3:02 PM</p>
                                        </div>
                                      </>)
                                    } else {
                                      return (<>
                                        <div className="chat-left">
                                          <h5>{res.text}</h5>
                                        </div>
                                      </>)
                                    }

                                  })
                                  : ""}
                              </div>

                            </div>
                            <div className="message-area-bottom">
                              <Form.Group >
                                <Form.Control as="textarea" value={query} onChange={(e) => {
                                  console.log("target valu", e.target.value)
                                  setQuery(e.target.value);
                                }} onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    if (chatTriggered) {
                                      return false;
                                    } else {
                                      if (e.keyCode === 13 && !e.shiftKey) {
                                        handleSubmitQuery()
                                      }

                                    }

                                  }
                                }} rows={2} placeholder="Ask me anything...." />
                              </Form.Group>
                              <i class="fa fa-paper-plane" onClick={chatTriggered ? () => { return false } : () => { handleSubmitQuery() }} aria-hidden="true"></i>
                            </div>

                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="chat-bot-content-left">
                            <h6>Define Persona</h6>

                            <div className="chat-bot-content-left-bottom">
                              <p>{chatbotData.prompt}</p>

                              <Button type="button" variant="unset" onClick={handleShowChatbot}><i class="fa fa-pencil" aria-hidden="true"></i> Edit</Button>
                            </div>

                          </div>
                          {/* <div className="saved-persona-result-outer">
                          <h5>Persona History</h5>
                          <div className="person-inner">
                            <div className="saved-persona-result-inner">
                              <div className="persona-top-area">
                                <h6>Primary</h6>
                                <div className="edit-dlt-btns">
                                  <Button type="text" variant="unset" className="edit-btn"><i class="fa fa-pencil" aria-hidden="true"></i></Button>
                                  <Button type="text" variant="unset" className="delete-btn"><i class="fa fa-trash" aria-hidden="true"></i></Button>
                                </div>
                              </div>

                              <p>A chatbot persona refers to the personality, characteristics, and communication style that a chatbot exhibits during interactions with users. It involves shaping the bot's behavior, language, and overall demeanor to create a more engaging and relatable user experience. </p>
                              <h5>16-02-2024</h5>
                            </div>
                            <div className="saved-persona-result-inner">
                              <div className="persona-top-area">
                                <div className="edit-dlt-btns">
                                  <Button type="text" variant="unset" className="edit-btn"><i class="fa fa-pencil" aria-hidden="true"></i></Button>
                                  <Button type="text" variant="unset" className="delete-btn"><i class="fa fa-trash" aria-hidden="true"></i></Button>
                                </div>
                              </div>

                              <p>A chatbot persona refers to the personality, characteristics, and communication style that a chatbot exhibits during interactions with users. It involves shaping the bot's behavior, language, and overall demeanor to create a more engaging and relatable user experience. </p>
                              <h5>16-02-2024</h5>
                            </div>
                            <div className="saved-persona-result-inner">
                              <div className="persona-top-area">
                                <div className="edit-dlt-btns">
                                  <Button type="text" variant="unset" className="edit-btn"><i class="fa fa-pencil" aria-hidden="true"></i></Button>
                                  <Button type="text" variant="unset" className="delete-btn"><i class="fa fa-trash" aria-hidden="true"></i></Button>
                                </div>
                              </div>

                              <p>A chatbot persona refers to the personality, characteristics, and communication style that a chatbot exhibits during interactions with users. It involves shaping the bot's behavior, language, and overall demeanor to create a more engaging and relatable user experience. </p>
                              <h5>16-02-2024</h5>
                            </div>
                          </div>

                        </div> */}
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="profile" title="Knowledge Base">
                      <Row>
                        <Col md={5}>
                          <div className="upload-file-knowledge-outer">
                            <div className="knowledge-base-top">
                              <Form.Group className="knowledge-base-top-upload-file">
                                <Form.Label>Upload File</Form.Label>
                                <Form.Control type="file"
                                  onChange={(e) => {
                                    if (!e.target.files || !e.target.files[0]) {
                                      setFileError("Please upload a file.");
                                      setDisabled(true)
                                    } else if (!allowedExtension.includes(e.target.files[0].type)) {
                                      setFileError("Please select a valid extension.");
                                      setDisabled(true)
                                    } else {
                                      setFile(e.target.files[0]);
                                      setDisabled(false)
                                      setFileError("");
                                    }
                                  }}
                                />
  <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                              </Form.Group>
                            
                              <button type="button" className={disabled ? "disable-btn" : ""} onClick={() => {
                                disabled ? swal("Error", "Please Uplaod a file", "error") : addKnowledgeBase(chatbotData.id)
                              }} disabled={disabled}>Upload file</button>
                            </div>
                            <span className="error">{fileError}</span>
                          </div>

                        </Col>
                      </Row>

                      <div className="knowledge-base">
                        <Table >
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>File Name</th>
                              <th>Date & Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {knowledgeBase && knowledgeBase.length > 0 ?
                              knowledgeBase.map((res) => {

                                return (
                                  <>
                                    <tr>
                                      <td>1</td>
                                      <td>Mark</td>
                                      <td>  16-02-2024, 03:34 PM</td>
                                    </tr>
                                  </>
                                )
                              })


                              :
                              <tr>
                                <td colSpan={6}>
                                  <div className="no-data-area">
                                    <img src={require("../../assets/images/no-data.gif")} alt="no-data" />
                                    <h6>No Data Found</h6>
                                  </div>
                                </td>
                              </tr>
                            }

                          </tbody>
                        </Table>
                      </div>
                    </Tab>
                    <Tab eventKey="contact" title="Leads Management">
                      <div className="knowledge-base">
                        <Table >
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone No.</th>
                              <th>Location</th>
                              <th>Date & Time</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Mark</td>
                              <td>adam@yopmail.com</td>
                              <td>9882163202</td>
                              <td>E281 Phase, 8A, Industrial Area, Sector 75, Sahibzada Ajit Singh Nagar</td>
                              <td>16-02-2024, 03:34 PM</td>
                              <td><button  onClick={() => {
                                // getUserConversation()
                                setShowConversationModal(true)
                              }}>View Conversation</button></td>
                            </tr>
                            
                          </tbody>
                        </Table>
                      </div>
                    </Tab>

                    <Tab eventKey="congiguration" title="Configuration">
                      <Row className="justify-content-center">
                        <Col md={12}>
                          <div className="configuration-area">
                            <Form>
                              <Row>
                                <Col md={6}>
                                  <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Get Code Snipet</Form.Label>
                                    <div className="configuration-content-inner">
                                      
                                      <input type="text" id="code_snippet" value={'<iframe src="'+window.location.origin+'/user-chat" frameborder="0"></iframe>'} readOnly/>
                                      <i class="fa fa-clone" onClick={()=> {
                                        copyContent('code_snippet')
                                      }} aria-hidden="true"></i>
                                      <span>{codeSuccess}</span>
                                    </div>
                                  </Form.Group>
                                </Col>

                                <Col md={6}>
                                  <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Get API</Form.Label>
                                    <div className="configuration-content-inner">
                                      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. </p>
                                      <i class="fa fa-clone" aria-hidden="true"></i>
                                    </div>
                                  </Form.Group>
                                </Col>

                                <Col md={6}>
                                  <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Get URL</Form.Label>
                                    <div className="configuration-content-inner">
                                    <input type="text" id="get_url" value={window.location.origin +'/userChat'} readOnly/>
                                      <i class="fa fa-clone" onClick={()=> {
                                        copyContent('get_url')
                                      }}  aria-hidden="true"></i>
                                      <span>{urlSuccess}</span>
                                    </div>
                                  </Form.Group>
                                </Col>


                              </Row>
                            </Form>

                            <Button type="button" variant="unset" className="dlt-chatbot">Delete Chatbot</Button>
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        
      }

      {/* chat-persona-modal */}
      <Modal show={showChatbot} onHide={handleCloseChatbot} centered className="chatbot-persona">
        <Modal.Header closeButton>
          <Modal.Title>Define Persona</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control as="textarea" rows={3} placeholder="Define Chatbot Persona" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseChatbot}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

     {/* user conversation modal */}

     <Modal show={showConversationModal} onHide={handleClose} centered size="lg" className="view-conversation-popup">
        {/* <Modal.Header closeButton>
      
        </Modal.Header> */}
        <Modal.Body>
          <div className="chat-bot-content-left">
            <div className="chatbot-name-top ">
              <h6>Chatbot</h6>
              <i class="fas fa-times" onClick={() => {handleClose()}}></i>
            </div>
            <div className="chat-bot-content-top">
              <div className="chat-outer-area">
                <div className="chat-left">
                  <h5>Hello</h5>
                  <p>Feb 9, 2024 3:02 PM</p>
                </div>
                <div className="chat-right">
                  <h5>Hi</h5>
                  <p>Feb 9, 2024 3:02 PM</p>
                </div>
              </div>

            </div>


          </div>
        </Modal.Body>

      </Modal>

    </>
  );
};
