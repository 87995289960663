import { useState } from "react"
import { context } from "./GlobalContext"


const Context = (props) => {
    
    const [activeTab, setActiveTab] = useState(window.location.href);
    const [showAddChatForm,setShowAddChatForm] = useState(false);

    var data={
        activeTab, setActiveTab,
        showAddChatForm,setShowAddChatForm,  
    }
    return (
        <context.Provider value={data}>
            {props.children}
        </context.Provider>
    );
}
export default Context;