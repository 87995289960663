import { useEffect, useState } from "react";
import { Login } from "./components/login/Login";
import { Home } from "./components/dashboard/Home";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./util/protectedRoute";
import { getItem } from "./util/localStorage";
import { useNavigate } from "react-router-dom";
import { PageNotFound } from "./components/Notfound.js";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import { useLocation } from "react-router";
import Footer from "./common/Footer";
// CSS
import "./App.css";
import "./assets/css/style.css";
import { MyContext } from "./config/config";

import { UsersList } from "./components/users/UsersList";
import { PredictionManagement } from "./components/predictionManagement/prediction";
import { Profile } from "./components/profile/AdminProfile";
import { ChangePassword } from "./components/changePassword/ChangePassword";
import { PredictionDetail } from "./components/predictionDetail/PredictionDetail.js";
import { TransactionManagement } from "./components/transactionManagement/TransactionManagement.js";
import { ContactUs } from "./components/users/ContactUs";
import { Chatbots } from "./components/chatbot/index.jsx";
import Chat from "./components/chat/chat.jsx";
import ChatbotDetail from "./components/chatbotDetail/ChatbotDetail.js";
import SocketChat from "./components/socketchat.js";
import AddChatBot from "./common/AddChatBot.js";
import UserFrame from "./components/userframe/frame.js";



function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [affiliateRequest, setAffiliateRequest] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [openLoad, setOpenLoad] = useState(false);
  const [editProfile, setEditProfile] = useState(false)
  useEffect(() => {
    if (getItem("admin_token")) {
    } else {
      navigate("/");
    }
  }, []);

  return (
    <MyContext.Provider value={{ affiliateRequest, setAffiliateRequest, filterType, setFilterType, openLoad, setOpenLoad, editProfile, setEditProfile }}>
      <>
        {location.pathname !== "/" && location.pathname !== "/user-chat"  && location.pathname !== "/userChat" ? (
          <>
            <Header />
            <Sidebar />
          </>
        ) :
          (
            <></>
          )}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/Home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />


          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <UsersList />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/predictions"
            element={
              <ProtectedRoute>
                <PredictionManagement />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/transaction"
            element={
              <ProtectedRoute>
                <TransactionManagement />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/transaction/:address"
            element={
              <ProtectedRoute>
                <TransactionManagement />
              </ProtectedRoute>}
          >
          </Route>

          <Route
            path="/prediction-detail/:id"
            element={
              <ProtectedRoute>
                <PredictionDetail />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/contact-us"
            element={
              <ProtectedRoute>
                <ContactUs />
              </ProtectedRoute>}
          >
          </Route>

          <Route
            path="/ChangePassword"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/profile"
            element={<ProtectedRoute>
              <Profile />
            </ProtectedRoute>}
          > </Route>

          <Route
            path="/user-chat"
            element={<Chat />}
          > </Route>

         <Route
            path="/userChat"
            element={<UserFrame />}
          > </Route>

          <Route
            path="/socket"
            element={<SocketChat/>}
          > </Route>

          

          <Route
            path="/chatbots"
            element={<ProtectedRoute>
              <Chatbots />
            </ProtectedRoute>}
          > </Route>

          <Route
            path="/chatbotdetail"
            element={<ProtectedRoute>
              <ChatbotDetail />
            </ProtectedRoute>}
          > </Route>
           

           <Route
            path="/addChatbot"
            element={<ProtectedRoute>
              <AddChatBot />
            </ProtectedRoute>}
          > </Route>


          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname !== "/" && location.pathname !== "/user-chat"  && location.pathname !== "/userChat"   ? <Footer /> : ""}

      </>
    </MyContext.Provider>
  );
}

export default App;
