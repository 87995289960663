import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { apiService } from "../../service/api.service";
import { ThreeDotSpinner } from "../loader"
import { context } from "../../GlobalContext/GlobalContext";
import moment from "moment";
import { Unauthorized } from "../../config/config";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import swal from "sweetalert";


const ChatbotDetail = () => {
  const navigate = useNavigate();
  const globalState = useContext(context);
  const [chatBot, setChatBot] = useState([]);
  const [loader, setLoader] = useState(false);
  const [chatTriggered, setChatTriggered] = useState(false);
  const [query, setQuery] = useState("");
  const [randomString, setRandomString] = useState("1234");
  const [socketResponse, setSocketResponse] = useState('');
  const [chat, setChat] = useState([]);
  const [checkStatus,setCheckStatus] = useState(false);
  const generatedAnswer = useRef("");
  const [chatBotId, setChatBotId] = useState("");
  const [showPreview, setShowPreview] = useState(false)
  const chatUrl = process.env.REACT_APP_BASE_URL + `/ws/chat/${randomString}/${chatBotId}`;
  const { sendMessage, lastMessage, readyState } = useWebSocket(`${chatUrl}`);


  useEffect(() => {
    getChatBot();
    makeid(6)
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      console.log("lastmassage--", readyState, JSON.parse(lastMessage.data));
      let messageResponse = JSON.parse(lastMessage.data);
      if (!messageResponse.stream) {
        setChatTriggered(false);
        let answer = generatedAnswer.current;
        setChat((prevObj) => [...prevObj, { type: "answer", text: answer }]);
        generatedAnswer.current = "";
        setSocketResponse([]);

      } else {
        setSocketResponse((prevResponse) => prevResponse + messageResponse.message);
        generatedAnswer.current = socketResponse;
      }

    }
  }, [lastMessage]);


  const getChatBot = () => {
    setLoader(true);
    apiService.getChatBot()
      .then((res) => {
        console.log('res.data?.data', res.data?.data)
        setLoader(false);
        if (res.data?.data?.length > 0) {
          setChatBot(res.data?.data)
          //   res.data?.data.map((r) => {
          //     setNameArray((prevArray) => [...prevArray, r.name])
          //   })
        }

      })
      .catch((err) => {
        setLoader(false);
        if (err.response?.status == 401) {
          Unauthorized();
        }
        console.log("err", err)
      })
  }

  const redirectTo = (data) => {
    localStorage.setItem("chatbot", JSON.stringify(data));
    navigate('/chatbots')
  }

  const handleSendMessage = useCallback((data) => sendMessage(data), []);

  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    setRandomString(result);
  }

  const handleSubmitQuery = () => {
    setChatTriggered(true);
    if (query.trim() == "") {
      swal("Warning", "Enter some query to ask!", "warning");
    } else {
      var data = { question: query }
      setChat((prevObj) => [...prevObj, { type: "question", text: query }]);
      setQuery("");
      handleSendMessage(JSON.stringify(data));
    }

  }




  const getStatus = (state) => {
    console(state)
    switch (state) {
      case 'pending':
        setCheckStatus(false)
        break;
      case 'training':
        setCheckStatus(false)
        break;

      case 'ready':
        setCheckStatus(false)
        break;

      case 'active':
        setCheckStatus(true)
        break;
      case 'disabled':
        setCheckStatus(false)
        break;

      default:
        setCheckStatus(false)
        break;
    }
    return checkStatus;
  }

  const changeStatus = (e,id) => {
    let element = document.getElementById(e.target.getAttribute('id'))
     var formdata = new FormData();
     setCheckStatus(!checkStatus);
        
    if(checkStatus){
      formdata.append('status', 'disabled')
    }else{
      formdata.append('status', 'active') 
    }
    console.log('event', element.checked)
   
    apiService.addKnowledgeBase(formdata,id)
              .then((res) => {
                getChatBot()
               console.log("res", res.data)
              })
              .catch((err) => {
                if(err.response?.status == 401){
                  Unauthorized();
                }
              })
  }
  return (
    <>
      <div className="dashboard-main-area">
        {loader && <ThreeDotSpinner />}
        <div className="dashboard-area main-home-top-area">
          <Row>

            <Col md={12} lg={12}>
              <div className="recent-conversation chat-bot-outer">
                <div className="chatbot-top">
                  <h3>My Chatbot</h3>
                  <Button type="button" variant="unset" onClick={() => { (navigate('/addChatbot')) }} >Create Chatbot</Button>
                </div>
                <div className="knowledge-base">

                  <Table >
                    <thead>
                      <tr>
                        <th>Chatbot Name</th>
                        <th>Created On</th>
                        <th>Total Interactions </th>
                        <th>Last Interaction</th>
                        <th>Status</th>
                        <th>Preview</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chatBot && chatBot.length > 0 ?
                        chatBot.map((res) => {
                          return (<>
                            <tr>
                              <td><button onClick={() => { redirectTo(res) }}>{res.name}</button></td>
                              <td>{moment(res.created_at).format("DD-MM-YYYY hh:mm A")}</td>
                              <td>{res.total_interactions}</td>
                              <td>{res.last_interaction ? res.last_interaction : "N/A"}</td>
                              <td >
                                <Form>
                                                     <Form.Check
                                                         type="switch"
                                                         id="custom-switch"
                                      
                                                         onChange={(e) => changeStatus(e,res.id)}
                                                         checked={res.status == 'active' ?true:false}
                                                     />
                                                 </Form>
                                
                              </td>
                              <td><a className={showPreview ? "disable-btn" : ""} disabled={showPreview} href="#" onClick={() => {
                                setShowPreview(true)
                                setChatBotId(res.id)
                              }}><i class="fas fa-eye"></i></a></td>
                            </tr>
                          </>)
                        })

                        : ""}

                    </tbody>
                  </Table>
                </div>
              </div>

            </Col>
          </Row>
        </div>
        {showPreview &&
          <div className="chatbot-content-bottom">
            <div className="chat-bot-content-left">
              <div className="chatbot-name-top">
                <h6>Chatbot</h6>
                <i onClick={() => {
                  setShowPreview(false)
                  setChat([])
                }} class="fas fa-times"></i>
              </div>

              <div className="chat-bot-content-top">
                <div className="chat-outer-area">
                  {chat && chat.length > 0 ?

                    chat.map((res) => {
                      if (res.type == 'answer') {
                        return (<>
                          <div className="chat-right">
                            <h5>{res.text}</h5>
                          </div>
                        </>)
                      } else {
                        return (<>
                          <div className="chat-left">
                            <h5>{res.text}</h5>
                          </div>
                        </>)
                      }

                    })
                    : ""}
                </div>

              </div>
              <div className="message-area-bottom">
                <Form.Group >
                  <Form.Control as="textarea" value={query} onChange={(e) => {
                    console.log("target valu", e.target.value)
                    setQuery(e.target.value);
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      if (chatTriggered) {
                        return false;
                      } else {
                        if (e.keyCode === 13 && !e.shiftKey) {
                          handleSubmitQuery()
                        }

                      }

                    }
                  }} rows={2} placeholder="Ask me anything...." />
                </Form.Group>
                <i class="fa fa-paper-plane" onClick={chatTriggered ? () => { return false } : () => { handleSubmitQuery() }} aria-hidden="true"></i>
              </div>

            </div>
          </div>
        }
      </div>

    </>
  );
};
export default ChatbotDetail;