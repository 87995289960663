import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import swal from "sweetalert";
import { context } from "../GlobalContext/GlobalContext";
import AddChatBot from "./AddChatBot";
import { apiService } from "../service/api.service";

const Sidebar = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const globalState = useContext(context);
  const [showChatbot, setShowChatbot] = useState(false);
  const [chatBot, setChatBot] = useState([]);
  const [nameArray, setNameArray] = useState([]);


  const location = useLocation()
  console.log(">>>>>>>>>", location.pathname.split("/")[1])
  useEffect(() => {
  }, [location.pathname])


  const navigation = (url = null, type) => {
    // globalState.setShowAddChatForm(false)
    // if (type === 'addChatbot') {
    //   globalState.setShowAddChatForm(true)
    // }
    if (url != null) {
      navigate(url);
    }
    globalState.setActiveTab(type);
    // setActive(type);

  }


  return (
    <>

      <div className="sidebar-overlay">


        {/* <div>
          <img src={sidebarOpenMobile ? require("../assets/images/sidebar-cross.svg").default : require("../assets/images/sidebar-menu.svg").default} alt="img" />
        </div> */}

        <section className="sidebar">
          <div className="logo">
            <img src={require("../assets/images/front-logo.png")} alt="" />
          </div>
          <ul>
            <li className={globalState.activeTab == "home" ? 'active' : ''}>
              <button onClick={() => {
                navigation('/Home', 'home')
              }}>
               Dashboard
              </button></li>

            <li onClick={() => {
              // setShowChatbot(!showChatbot)
              navigation('/chatbotdetail', 'chatbot')
            }} className={globalState.activeTab == "chatbot" ? 'active chatbot-tab' : 'chatbot-tab'}>
         <button> Chatbots</button>
          
            </li>
            {/* {showChatbot &&
              <ul className="chatbot-bottom">
                <li className={globalState.activeTab == "addChatbot" ? 'active' : ''} onClick={() => { navigation('chatbots', 'addChatbot') }}>Create New Chatbot</li>
                {chatBot && chatBot.length > 0 &&
                  chatBot.map((res, key) => {
                    return (
                      <>
                        <li onClick={() => { redirectTo(res, res?.id) }} className={globalState.activeTab == `${'chatbot_' + res?.id}` ? 'active' : ''} >{res.name}</li>
                      </>
                    )
                  })
                }

              </ul>
            } */}

            <li className={globalState.activeTab == "profile" ? 'active' : ''}>
              <button onClick={() => {
                navigation('/profile', 'profile')
              }}>
             Profile
              </button>
            </li>

            <li>
              <button onClick={() => {
                swal({
                  title: 'Are you sure?',
                  text: 'You want to logout?',
                  icon: 'warning',
                  buttons: ['Cancel', 'Yes, logout'],
                  dangerMode: true,
                }).then((confirmed) => {
                  if (confirmed) {

                    localStorage.clear();
                    navigate("/");

                  }
                })
              }}>
              Logout
              </button>
            </li>
          </ul>



        </section>
      </div>
      {/* {globalState.showAddChatForm &&
        <AddChatBot data={nameArray} setChatData={() => { getChatBot() }} />
      } */}
    </>
  );
};
export default Sidebar;
