import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { apiService } from "../../service/api.service";
import { Unauthorized } from "../../config/config";

export function Profile() {

  const [isEditable, setIsEditable] = useState(false);
  const [profileFields, setProfileFields] = useState({
     name:"",
     email:"",
     phone:"",
     designation:""
  })
  const [profileFieldError, setProfileFieldError] = useState({
        name:"",
        email:"",
        phone:"",
        designation:""
  })

  const [companyField, setCompanyField] = useState({
    name:"",
    website:"",
    description: "",
    api_key: ""
  })  

  const [companyFieldError, setCompanyFieldError] = useState({
    name:"",
    website:"",
    description: "",
    api_key: ""
  }) 

   useEffect(() => {
    getProfileData();
   }, []) 

   const getProfileData = () => {
      apiService.companyProfile()
                .then((res) => {
                   if(res.data?.user_profile){
                    console.log("res.data?.user_profile", res.data?.user_profile)
                    setProfileFields(res.data?.user_profile)
                   }
                   if(res.data?.company_profile){
                     console.log("res.data?.company_profile", res.data?.company_profile)
                    setCompanyField(res.data?.company_profile)
                   }
                })
                .catch((err) => {
                   if(err.response?.status == 401){
                      Unauthorized();
                   }  
                })
   }

   const setFieldValue = (field, value) => {
    if (value) {
        setProfileFields((prevObj) => {
        return {
          ...prevObj,
          [field]: value,
        };
      });

      setProfileFieldError((prevObj) => {
        return {
          ...prevObj,
          [field]: '',
        };
      });
    }
}

const setCompanyFieldValue = (field, value) => {
    if (value) {
        setCompanyField((prevObj) => {
        return {
          ...prevObj,
          [field]: value,
        };
      });
      
      setCompanyFieldError((prevObj) => {
        return {
          ...prevObj,
          [field]: '',
        };
      }); 

    }
}

const showCompanyError = (field, error) => {
    setCompanyFieldError((prevObj) => {
      return {
        ...prevObj,
        [field]: error,
      };
    });
  };



    const showError = (field, error) => {
        setProfileFieldError((prevObj) => {
          return {
            ...prevObj,
            [field]: error,
          };
        });
      };

   const updateProfileData = () => {
    var formdata = new FormData();
    //    console.log(profileFields)
    //    console.log(companyField)
     var data = {user_profile:profileFields,
                  company_profile: companyField}
     apiService.updateProfileData(data)
               .then((res) => {
                  setIsEditable(false);
                   console.log("res", res)
               })
               .catch((err) => {
                 if(err.response?.status == 401){
                    Unauthorized()
                 }
               })
   }

    return (
        <div className="dashboard-main-area">
            <div className="dashboard-area main-home-top-area">
                <Row>

                    <Col md={12} lg={12}>
                        <div className="recent-conversation">
                            <div className="chatbot-top">
                                <h3>Profile</h3>
                                {!isEditable &&
                                <Button type="button" onClick={() => {setIsEditable(true)}} variant="unset"><i class="far fa-edit"></i> Edit</Button>
                                }
                                {isEditable &&
                                <Button type="button" onClick={() => {updateProfileData()}} variant="unset"><i class="fas fa-save"></i> Save</Button>
                                }
                            </div>
                            <div className="profile-outer">
                                <div className="configuration-area">
                                    <h5>Your Profile</h5>
                                    <Form>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" value={profileFields.name}
                                                     onChange={(e) => {
                                                        if(e.target.value.trim() == ""){
                                                            showError('name', 'Field is Required.')
                                                            profileFields.name = ""
                                                        }else{
                                                            setFieldValue('name', e.target.value)
                                                        }
                                                         
                                                     }}
                                                     readOnly={!isEditable} placeholder="Mark" />
                                                     <span className="error">{profileFieldError.name}</span>
                                                </Form.Group>

                                            </Col>

                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="text" value={profileFields.email}
                                                     onChange={(e) => {
                                                        if(e.target.value.trim() == ""){
                                                            showError('email', 'Field is Required.')
                                                            profileFields.email = "";
                                                        }else{
                                                            setFieldValue('email', e.target.value)
                                                        }
                                                         
                                                     }}
                                                     readOnly={!isEditable} placeholder="mark@gmail.com" />
                                                      <span className="error">{profileFieldError.email}</span>
                                                </Form.Group>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label>Phone Nmuber</Form.Label>
                                                    <Form.Control type="text" value={profileFields.phone}
                                                      onChange={(e) => {
                                                        if(e.target.value.trim() == ""){
                                                            showError('phone', 'Field is Required.')
                                                            profileFields.phone = "";
                                                        }else{
                                                            setFieldValue('phone', e.target.value)
                                                        }
                                                         
                                                     }}
                                                    
                                                     readOnly={!isEditable} placeholder="9884512563" />
                                                     <span className="error">{profileFieldError.phone}</span>
                                                </Form.Group>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label>Designation</Form.Label>
                                                    <Form.Control type="text" value={profileFields.designation}
                                                     onChange={(e) => {
                                                        if(e.target.value.trim() == ""){
                                                            showError('designation', 'Field is Required.')
                                                            profileFields.designation = ""
                                                        }else{
                                                            setFieldValue('designation', e.target.value)
                                                        }
                                                         
                                                     }}
                                                     readOnly={!isEditable} placeholder="Developer" />
                                                     <span className="error">{profileFieldError.designation}</span>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                <div className="configuration-area admin-profile">
                                    <h5>Company Profile</h5>
                                    <Form>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Company Name</Form.Label>
                                                    <Form.Control type="text" value={companyField.name}
                                                     onChange={(e) => {
                                                        if(e.target.value.trim() == ""){
                                                            showCompanyError('name', 'Field is Required.')
                                                            companyField.name = ""
                                                        }else{
                                                            setCompanyFieldValue('name', e.target.value)
                                                        }
                                                         
                                                     }}
                                                     readOnly={!isEditable} placeholder="Mark" />
                                                     <span>{companyFieldError.name}</span>
                                                </Form.Group>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label>Website URL</Form.Label>
                                                    <Form.Control type="text" value={companyField.website}
                                                      onChange={(e) => {
                                                        if(e.target.value.trim() == ""){
                                                            showCompanyError('website', 'Field is Required.')
                                                            companyField.website = ""
                                                        }else{
                                                            setCompanyFieldValue('website', e.target.value)
                                                        }
                                                         
                                                     }}
                                                     readOnly={!isEditable} placeholder="mark@gmail.com" />
                                                     <span>{companyFieldError.website}</span>
                                                </Form.Group>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Api Key</Form.Label>
                                                    <Form.Control type="text" value={companyField.api_key}
                                                    onChange={(e) => {
                                                        if(e.target.value.trim() == ""){
                                                            showCompanyError('api_key', 'Field is Required.')
                                                            companyField.api_key = ""
                                                        }else{
                                                            setCompanyFieldValue('api_key', e.target.value)
                                                        }
                                                         
                                                     }}
                                                     readOnly={!isEditable}  placeholder="" />
                                                     <span>{companyFieldError.api_key}</span>
                                                    {/* <Form.Control type="text" placeholder="9884512563" /> */}
                                                </Form.Group>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label>About Organization</Form.Label>
                                                    <Form.Control as="textarea" value={companyField.description}
                                                    onChange={(e) => {
                                                        if(e.target.value.trim() == ""){
                                                            showCompanyError('description', 'Field is Required.')
                                                            companyField.description = ""
                                                        }else{
                                                            setCompanyFieldValue('description', e.target.value)
                                                        }
                                                         
                                                     }}
                                                     readOnly={!isEditable} rows={5} placeholder="9884512563" />
                                                     <span>{companyFieldError.description}</span>
                                                    {/* <Form.Control type="text" placeholder="9884512563" /> */}
                                                </Form.Group>
                                            </Col>

                                        </Row>

                                    </Form>


                                 
                                </div>
                            </div>



                        </div>

                    </Col>
                </Row>
            </div>

        </div>
    )
}
export default Profile;