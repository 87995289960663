import React, { useContext, useEffect, useState } from "react";
import { Container, Dropdown,OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { MyContext, formatBalance,feedWalletAddress } from "../config/config";
import { apiService } from "../service/api.service";
import {CopyToClipboard} from 'react-copy-to-clipboard';
const Header = () => {
  const navigate = useNavigate();
  const { editProfilr, setEditProfile } = useContext(MyContext)
  const [copied,setcopied] = useState(false)
  const [balance, setBalance] = useState(0);
  useEffect(() => {
    getBalance()
  }, []);
  const copytooltip = (
    <Tooltip id="tooltip">
    {copied?"Copied" :"Copy"}
    </Tooltip>
    
  );
  const setcopytext =()=>{
    setTimeout(() => {
      setcopied(false)
    }, "1000");
    
  }
  const getBalance = async () => {

    // const response = await apiService.getBalance();
    // if (response.status == 200) {
    //   setBalance(parseFloat(response.data.data).toFixed(4))
    //   localStorage.setItem("balance", parseFloat(response.data.data).toFixed(4))
    // }
  }

  return (
    <>
      <div className="admin-header">
        <Container fluid>
          <div className="header-right">

            <div className="logo-mob">
              <img src={require("../assets/images/logo-mob.png")} alt="img" />
            </div>

            <div className="header-top-wallet-area">
        
               
             

              <div className="logout-btn">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={require("../assets/images/user.png")} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>

                  <Dropdown.Item
                      onClick={() => { navigate("/profile") }}
                    >
                      <i className="fa fa-user" aria-hidden="true"></i>
                      Profile
                    </Dropdown.Item>
                   
                    <Dropdown.Item
                      onClick={() => { navigate("/ChangePassword") }}
                    >
                      <i className="fa fa-lock" aria-hidden="true"></i>
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        swal({
                          title: 'Are you sure?',
                          text: 'You want to logout?',
                          icon: 'warning',
                          buttons: ['Cancel', 'Yes, logout'],
                          dangerMode: true,
                        }).then((confirmed) => {
                          if (confirmed) {

                            localStorage.clear();
                            navigate("/");

                          }
                        })
                      }}

                    >
                      <i className="fa fa-sign-out" aria-hidden="true"></i>
                      Log Out
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

          </div>

        </Container>
      </div>
    </>
  );
};
export default Header;
